import { render, staticRenderFns } from "./Calendar.vue?vue&type=template&id=3430d1fb&scoped=true&"
import script from "./Calendar.vue?vue&type=script&lang=ts&"
export * from "./Calendar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3430d1fb",
  null
  
)

export default component.exports