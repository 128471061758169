

















import Vue from 'vue'
import FullCalendar, { CalendarOptions, EventDropArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { EventResizeDoneArg, Draggable, DropArg } from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import axios from '@/axios-instance'
import { FirstDayLastDay } from '@/interfaces'
import { mapActions } from 'vuex'

interface CalendarParams {
  startDate: Date
  endDate: Date
  workerId?: number
}

interface RevertFunc {
  (): void
}

interface PatchedTask {
  id: number
  event?: Record<string, string>
  revert?: RevertFunc
  startDate?: Date | null
  endDate?: Date | null
}

export default Vue.extend({
  components: { FullCalendar },
  data() {
    return {
      busy: false,
      calendarView: 'week',
      canDrop: true,
      selectedWorkerId: null as null | number,
    }
  },
  computed: {
    calendar() {
      return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi()
    },
    calendarOptions() {
      return {
        timeZone: 'UTC',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        themeSystem: 'bootstrap',
        firstDay: 1,
        locale: frLocale,
        weekends: true,
        selectable: true,
        height: 700,
        allDaySlot: false,
        events: [],
        hiddenDays: [0, 6],
        customButtons: {
          prev: {
            text: 'prev',
            click: () => {
              this.prev()
            },
          },
          next: {
            text: 'next',
            click: () => {
              this.next()
            },
          },
          today: {
            text: "Aujourd'hui",
            click: () => {
              this.today()
            },
          },
          month: {
            text: 'Mois',
            click: () => {
              this.setActiveTask(null)
              this.changeView('month')
            },
          },
          week: {
            text: 'Semaine',
            click: () => {
              this.setActiveTask(null)
              this.changeView('week')
            },
          },
        },
        headerToolbar: {
          center: 'month week',
          right: 'today prev,next',
        },
        eventDrop: (eventInfos: EventDropArg) => {
          if (this.canDrop) {
            this.updateEvent(eventInfos)
          } else {
            eventInfos.revert()
          }
        },
        eventResize: (eventInfos: EventResizeDoneArg) => {
          if (this.canDrop) {
            this.updateEvent(eventInfos)
          } else {
            eventInfos.revert()
          }
        },
        eventClick: (eventInfos: EventClickArg) => {
          this.setTaskAsActive(eventInfos)
        },
        droppable: true,
        drop: (eventInfos: DropArg) => {
          this.dropTask(eventInfos)
        },
        businessHours: {
          startTime: '08:00',
          endTime: '17:00',
        },
      } as CalendarOptions
    },
    activeTask() {
      return this.$store.state.activeTask
    },
    calendarWorker() {
      return this.$store.state.calendarWorker
    },
  },
  watch: {
    '$store.state.user': function (newVal) {
      if (newVal !== null) {
        this.refreshCalendar()
      }
    },
    calendarView() {
      this.updateCalendar()
    },
    calendarWorker(workerId) {
      // Refresh calendar if a woker has been selected
      this.setActiveTask(null)
      this.updateCalendar()
      this.selectedWorkerId = workerId
    },
  },
  mounted() {
    // Initial setup
    this.updateCalendar()

    const dragMe = document.getElementById('dragMe')
    if (dragMe) {
      new Draggable(dragMe)
    }

    // Refresh calendar if a global event has been emited
    this.$root.$on('updateCalendar', () => {
      this.updateCalendar()
    })

    // Trigger a resize on sidebar open/close
    this.$root.$on('toggleSidebar', () => {
      this.refreshCalendar()
    })
  },
  methods: {
    ...mapActions(['setActiveTask', 'setGlobalToast']),
    formatDate(startDate: Date | undefined, endDate: Date | undefined): FirstDayLastDay {
      let start = new Date()
      let end = new Date()

      if (typeof startDate !== 'undefined') {
        start = new Date(startDate)
      }

      if (typeof endDate !== 'undefined') {
        end = new Date(endDate)
      }

      const startDay = start.getDate()
      const startMonth = start.getMonth()
      const startYear = start.getFullYear()
      const endDay = end.getDate()
      const endMonth = end.getMonth()
      const endYear = end.getFullYear()

      return {
        startDate: new Date(startYear, startMonth, startDay),
        endDate: new Date(endYear, endMonth, endDay),
      }
    },
    today(): void {
      this.calendar.today()
      this.updateCalendar()
    },
    prev(): void {
      this.calendar.prev()
      this.updateCalendar()
    },
    next(): void {
      this.calendar.next()
      this.updateCalendar()
    },
    changeView(view: string): void {
      this.calendarView = view
      switch (view) {
        case 'week':
          this.calendar.changeView('timeGridWeek')
          break

        case 'month':
          this.calendar.changeView('dayGridMonth')
          break
      }
    },
    updateCalendar(): void {
      if (!this.busy) {
        // Reset events
        this.resetEvents()

        const params = {} as CalendarParams
        const calendarData = this.calendar.getCurrentData()
        const formattedDates = this.formatDate(
          calendarData.dateProfile?.activeRange?.start,
          calendarData.dateProfile?.activeRange?.end
        )

        ;(params.startDate = formattedDates.startDate), (params.endDate = formattedDates.endDate)

        if (this.calendarWorker != null) {
          params.workerId = this.calendarWorker
        }

        this.busy = true

        axios
          .get('/calendar_search', {
            params,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.updateEvents(response.data)
            }
          })
          .finally(() => {
            this.busy = false

            this.refreshCalendar()
          })
      }
    },
    resetEvents(): void {
      const oldEvents = this.calendar.getEvents()

      oldEvents.forEach((eventInfos: EventApi) => {
        const oldEvent = this.calendar.getEventById(eventInfos.id)
        oldEvent?.remove()
      })
    },
    updateEvents(events: EventInput[]): void {
      // Reset events
      this.resetEvents()

      // Inject events in blank array
      events.forEach((event: EventInput) => {
        this.calendar.addEvent({
          id: event.id,
          title: event.label,
          start: event.startDate,
          end: event.endDate,
          editable: !event.isLocked,
          extendedProps: {
            isLocked: event.isLocked,
            status: event.status,
          },
        } as EventInput)
      })
    },
    updateEvent(eventInfos: EventDropArg | EventResizeDoneArg, update?: boolean) {
      let currentTask = this.activeTask

      if (currentTask === null) {
        currentTask = eventInfos.event
      }

      if (update !== true) {
        // Reset active task
        this.setActiveTask(null)
      }

      const task = {
        id: parseInt(eventInfos.event.id),
        startDate: eventInfos.event.start,
        endDate: eventInfos.event.end,
      }

      this.patchEvent(task)
    },
    setTaskAsActive(taskInfos: EventClickArg): void {
      // To enhance UX, the current active task (if any) should be reset
      this.setActiveTask(null)

      axios.get(`/task/${taskInfos.event.id}`).then((response) => {
        if (response && response.success === true) {
          this.setActiveTask(response.data)
        }
      })
    },
    refreshCalendar(): void {
      // Kind of a trick, this forces the browser to repaint the page, thus making FullCalendar display nicely
      setTimeout(function () {
        window.dispatchEvent(new Event('resize'))
      }, 1)
    },
    patchEvent(task: PatchedTask, update = false): void {
      // Freeze drop
      this.canDrop = false

      // Attempt to patch event
      axios
        .patch(`/task/${task.id}`, task)
        .then((response) => {
          // Updating task to be in sync with what's on the database
          const oldEvent = this.calendar.getEventById(task.id.toString())
          oldEvent?.remove()

          this.calendar.addEvent({
            id: response.data.id,
            title: response.data.label,
            start: response.data.startDate,
            end: response.data.endDate,
            editable: !response.data.isLocked,
            extendedProps: {
              isLocked: response.data.isLocked,
              status: response.data.status,
            },
          })

          if (update === true) {
            // Send update event
            this.$root.$emit('taskUpdated')

            // Update store object
            this.setActiveTask(response.data)
          }
        })
        .catch((error) => {
          // Not fond of this check, but since the task object is a weird one (can be fully formed by FullCalendar Drop,
          // can be a weird mix made by Resize / Move), we have to use it.
          if (typeof task.revert === 'function') {
            task.revert()
          }

          // Could this be that the user doesn't have the sufcient rights?
          if (error.response?.status === 403) {
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content: "Vous n'avez pas les droits suffisants pour effectuer cette opération.",
            })
          } else {
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content: 'Impossible de modifier cette tâche. Merci de reéssayer plus tard.',
            })
          }
        })
        .finally(() => {
          this.canDrop = true
        })
    },
    dropTask(taskInfos: DropArg): void {
      const startDate = new Date(taskInfos.date)
      const endDate = new Date(startDate)
      endDate.setMinutes(endDate.getMinutes() + 45)

      let task = { ...this.activeTask }
      task.startDate = startDate.toUTCString()
      task.endDate = endDate.toUTCString()
      task.type = this.activeTask.type?.id
      task.quality = this.activeTask.quality?.id

      // A task should have a worker assigned to it in order to be planned
      if (!this.selectedWorkerId && !this.activeTask.worker?.id) {
        this.setGlobalToast({
          type: 'danger',
          title: 'Erreur !',
          content: "Cette tâche n'a pas de Réalisateur assigné. Choisissez-en un dans l'agenda ou modifier la tâche.",
        })
      } else {
        task.worker = this.selectedWorkerId ? this.selectedWorkerId : this.activeTask.worker?.id
        // If the task was set as 'TODO', it is automatically set as 'PLANNED', otherwise we shall not change
        // its status
        if (task.status === 'TODO') {
          task.status = 'PLANNED'
        }
        this.patchEvent(task, true)
      }
    },
    canPlanifyTask() {
      // A task can be added to the calendar if an activeTask is set
      // and if either it has no start/end date, and it has a worker OR a worker is selected in the calendar
      return (
        this.activeTask &&
        (this.activeTask.starDate === null || this.activeTask.endDate === null) &&
        ((this.activeTask.worker && this.activeTask.worker.id !== null) || this.selectedWorkerId !== null)
      )
    },
    getTaskStatus(status: string | null) {
      return status ? status.toLowerCase() : null
    },
  },
})
