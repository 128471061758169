





































































































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { BVSelect } from '@/interfaces'
import axios from '@/axios-instance'
import DateRangePicker from 'vue2-daterange-picker'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

interface SearchParams {
  workers: number[]
  requesters: number[]
  types: number[]
  statuses: string[]
  dateReal: string | null
  datePlanStart?: string
  datePlanEnd?: string
  duration?: number
  page: number
  textSearch: string | null
}

interface Status {
  text: string
  value: string
  class: string
}

export default Vue.extend({
  components: { Multiselect, DateRangePicker },
  data() {
    return {
      componentFilters: {
        workers: [] as BVSelect[],
        requesters: [] as BVSelect[],
        types: [] as BVSelect[],
        statuses: [
          { text: 'Brouillon', value: 'DRAFT', class: 'pill-draft' },
          {
            text: 'À faire',
            value: 'TODO',
            class: 'pill-todo',
          },
          { text: 'Planifiée', value: 'PLANNED', class: 'pill-planned' },
        ] as Status[],
        dateReal: null,
        datePlan: {
          startDate: null as Date | null,
          endDate: null as Date | null,
        },
        duration: null as number | null,
        textSearch: null as string | null,
      },
      page: 1,
      visibleFilters: false,
      statuses: [
        { text: 'Brouillon', value: 'DRAFT', class: 'pill-draft' },
        {
          text: 'À faire',
          value: 'TODO',
          class: 'pill-todo',
        },
        { text: 'Planifiée', value: 'PLANNED', class: 'pill-planned' },
        { text: 'Fermée (max 500 résultats)', value: 'CLOSED', class: 'pill-closed' },
      ] as Status[],
      dateRangePickerLocale: {
        direction: 'ltr',
        format: 'dd/mm/yy',
        separator: ' - ',
        applyLabel: 'Appliquer',
        cancelLabel: 'Annuler',
        daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        monthNames: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        firstDay: 1,
      },
      busy: false,
    }
  },
  computed: {
    ...mapState(['taskWorkers', 'filters', 'taskTypes', 'user', 'taskRequesters']),
    ...mapGetters(['isAdmin', 'isWorker', 'isRequester']),
  },
  watch: {
    user() {
      this.setStatusFilter()
    },
  },
  mounted() {
    // Setting status filters
    this.setStatusFilter()

    // Init first search
    this.launchSearch()

    // Let's react to pagination change
    this.$root.$on('changeFiltersPage', (page: number) => {
      this.page = page
      this.launchSearch()
    })

    // If a task has been updated, we should update the list as well
    this.$root.$on('taskUpdated', () => {
      this.launchSearch()
    })
  },
  methods: {
    ...mapActions(['setFilters', 'setTasksList', 'setActiveTask']),
    launchSearch(reset = false) {
      if (!this.busy) {
        // On search, the active task should be cancelled
        if (reset === true) {
          this.setActiveTask(null)
        }

        // New object that's going to be used to set filters in VueX.
        // Filters are set in VueX as it's easier to manage pagination, which is controlled by another component.
        const params = {
          workers: [],
          requesters: [],
          types: [],
          statuses: [],
          page: this.page,
          textSearch: null,
          dateReal: null,
        } as SearchParams

        if (reset === true) {
          params.page = 1
        }

        // Adding workers to filter
        if (this.componentFilters.workers.length > 0) {
          this.componentFilters.workers.forEach((worker: BVSelect) => {
            params.workers.push(parseInt(worker.value))
          })
        }

        // Adding requesters to filter
        if (this.componentFilters.requesters.length > 0) {
          this.componentFilters.requesters.forEach((requester: BVSelect) => {
            params.requesters.push(parseInt(requester.value))
          })
        }

        // Adding types to filter
        if (this.componentFilters.types.length > 0) {
          this.componentFilters.types.forEach((type: BVSelect) => {
            params.types.push(parseInt(type.value))
          })
        }

        // Adding statuses to filter
        if (this.componentFilters.statuses.length > 0) {
          this.componentFilters.statuses.forEach((status: BVSelect) => {
            params.statuses.push(status.value)
          })
        }

        // Adding realization date to filter
        if (this.componentFilters.dateReal !== null) {
          params.dateReal = this.componentFilters.dateReal
        }

        // Adding min/max plan date to filter
        if (this.componentFilters.datePlan.startDate !== null) {
          params.datePlanStart = this.formatDate(this.componentFilters.datePlan.startDate)
        }

        if (this.componentFilters.datePlan.endDate !== null) {
          params.datePlanEnd = this.formatDate(this.componentFilters.datePlan.endDate)
        }

        // Adding duration to filter
        if (this.componentFilters.duration !== null) {
          params.duration = this.componentFilters.duration
        }

        // Adding text search to filter
        if (this.componentFilters.textSearch !== null) {
          params.textSearch = this.componentFilters.textSearch
        }

        // Set filters inside store
        this.setFilters(params)

        // Launching search
        this.busy = true
        axios
          .get('/search', { params })
          .then((response) => {
            if (response && response.success === true) {
              if (response.data.tasks.length > 0) {
                this.setTasksList(response.data)
              } else {
                this.setTasksList({})
              }
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    toggleFilters() {
      this.visibleFilters = !this.visibleFilters
    },
    formatDate(date: Date): string {
      const year = date.getFullYear().toString()
      const month = date.getMonth() + 1
      const searchMonth = month.toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      return `${year}-${searchMonth}-${day}`
    },
    resetMaxPlan(): void {
      this.componentFilters.datePlan = {
        startDate: null,
        endDate: null,
      }
    },
    setStatusFilter(): void {
      if (!this.isAdmin && this.isWorker) {
        const statuses = [{ text: 'Planifiée', value: 'PLANNED', class: 'pill-planned' }]
        this.statuses = statuses
        this.componentFilters.statuses = statuses
      }
    },
    selectMe() {
      // If the current user is in the possible selection, they are added to the multiselect.
      this.componentFilters.requesters = []
      if (
        this.taskRequesters.filter((requester: BVSelect) => {
          return requester.value === this.user.id
        }).length > 0
      ) {
        this.componentFilters.requesters.push({
          text: this.user.fullName,
          value: this.user.id,
        })
      }
    },
    removeStatus(status: Status) {
      let i = 0
      this.componentFilters.statuses.forEach((currentStatus) => {
        if (currentStatus.value === status.value) {
          this.componentFilters.statuses.splice(i, 1)
        }
        i++
      })
    },
  },
})
