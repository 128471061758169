














































import Vue from 'vue'
import Calendar from '@/components/Calendar.vue'
import Filters from '@/components/Filters.vue'
import TasksList from '@/components/TasksList.vue'
import Map from '@/components/Map.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  components: { Calendar, Filters, TasksList, Map },
  data() {
    return {
      selectedWorker: null,
      token: '',
    }
  },
  computed: {
    ...mapState(['taskWorkers', 'user']),
    ...mapGetters(['isAdmin', 'isWorker', 'isRequester']),
  },
  watch: {
    user(newVal) {
      if (newVal !== null && !this.isAdmin && this.isWorker) {
        this.selectedWorker = this.user.id
      } else {
        this.selectedWorker = null
      }
      this.setCalendarWorker(this.selectedWorker)
      this.selectWorker()
    },
  },
  mounted() {
    this.token = this.$cookies.get('authToken')
  },
  methods: {
    ...mapActions(['setCalendarWorker']),
    selectWorker(): void {
      this.setCalendarWorker(this.selectedWorker)
    },
  },
})
