
















  import Vue from 'vue'
  import L from 'leaflet'
  import { latLng, latLngBounds } from 'leaflet'
  import { mapState, mapActions } from 'vuex'
  import axios from '@/axios-instance'

  interface Marker {
    id: number
    lat: number
    lng: number
    status: string
  }

  export default Vue.extend({
    data() {
      return {
        // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        defaultCenter: latLng(45.36093371282, 2.6789397001266),
        bounds: latLngBounds([[45.36093371282, 2.6789397001266]]),
        defaultZoom: 5,
        markers: [] as Marker[],
        mapOptions: {
          zoomControl: true,
          scrollWheelZoom: false,
          dragging: !L.Browser.mobile,
        },
      }
    },
    computed: {
      ...mapState(['taskslist']),
    },
    watch: {
      taskslist(list) {
        this.markers = list.markers
        this.bounds = list.markers
      },
    },
    methods: {
      ...mapActions(['setActiveTask']),
      setActive(taskId: number) {
        axios.get(`/task/${taskId}`).then((response) => {
          this.setActiveTask(response.data)
        })
      },
      getIcon(status: string) {
        let color
        switch (status) {
          case 'DRAFT':
            color = '#FF8A80'
            break

          case 'CLOSED':
            color = '#263238'
            break

          case 'TODO':
            color = '#9d9de9'
            break

          case 'PLANNED':
            color = '#79c979'
            break

          default:
            color = 'gray'
        }

        return L.divIcon({
          className: 'my-custom-pin',
          html: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 23.5 31" style="enable-background:new 0 0 23.5 31;" height="32" width="24">
<style type="text/css">
	.st0{fill:${color};stroke:${color};}
	.st1{fill:#ffffff;}
</style>
<g>
	<g>
		<path d="M11.7,0.5C5.5,0.5,0.5,5.6,0.5,11.8c0,8.9,10.2,18,10.6,18.4c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
			C12.8,29.9,23,20.7,23,11.8C23,5.6,18,0.5,11.7,0.5L11.7,0.5z" style="fill:${color};stroke:${color};"/>
		<path d="M11.7,31c-0.3,0-0.7-0.1-0.9-0.4C10.4,30.2,0,20.9,0,11.8C0,5.3,5.3,0,11.7,0c6.5,0,11.7,5.3,11.7,11.8
			c0,8.8-9.7,17.8-10.8,18.8C12.4,30.9,12.1,31,11.7,31z M11.7,1C5.8,1,1,5.9,1,11.8c0,8.6,10,17.7,10.5,18.1c0.2,0.1,0.4,0.1,0.6,0
			c1.1-1,10.5-9.7,10.5-18.1C22.5,5.9,17.7,1,11.7,1z" style="fill:#ffffff;"/>
	</g>
	<circle class="st1" cx="11.7" cy="11.7" r="6.3"/>
</g>
</svg>`,
          iconAnchor: [16, 24],
        })
      },
    },
  })
