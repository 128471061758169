

































import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import { Task } from '@/interfaces'

export default Vue.extend({
  data() {
    return {
      fields: [
        { key: 'label', label: 'Nom' },
        { key: 'type', label: 'Type' },
        { key: 'worker.fullName', label: 'Prestataire' },
        { key: 'status', label: 'Statut' },
      ],
    }
  },
  computed: {
    ...mapState(['taskslist']),
  },
  methods: {
    ...mapActions(['setActiveTask']),
    change(page: number): void {
      this.$root.$emit('changeFiltersPage', page)
    },
    getStatus(status: string): string {
      switch (status) {
        case 'DRAFT':
          return 'Brouillon'

        case 'CLOSED':
          return 'Fermée'

        case 'TODO':
          return 'À faire'

        case 'PLANNED':
          return 'Planifiée'

        default:
          return 'Inconnu'
      }
    },
    getType(type: Record<string, string> | null) {
      return type === null ? 'Inconnu' : type.label
    },
    click(task: Task) {
      this.setActiveTask(task)
    },
    rowClass(item: Record<string, string>, type: string): string {
      if (item && type === 'row') {
        return item.status.toLowerCase()
      } else {
        return ''
      }
    },
  },
})
